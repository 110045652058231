import axios from 'axios'
console.log('process.env.VUE_APP_BASEURL', process.env.VUE_APP_BASE_API)

const service = axios.create({
  // baseURL: 'https://www.33k.vip', // url = base url + request url
  baseURL: 'http://192.168.110.66:5502/', // url = base url + request url
  timeout: 50000, // request timeout 
  headers: { 'Content-Type': 'application/json;charset=utf-8' },
})
service.interceptors.request.use(
  config => {
    // config.headers = {
    //   "YH-REPORT-USERID": localStorage.get("userId"),
    //   "YH-REPORT-TOKEN": localStorage.get("token"),
    // }
    return config
  },
  error => {
    console.log(error)
    Promise.reject(error)
  }
)
// response拦截器

export default service
