import request from '@/utils/request'
/**
 * 获取Openid
 * 
 * @param {*} params 
 * @returns 
 */
export function api_getOpenid (params) {
  return request({
    url: '/api/wx/openid',
    method: 'get',
    params: params
  })
}
/**
 * 优惠券兑换
 * @param {*} params 
 * @returns 
 */
export function api_exchange (params) {
  return request({
    url: '/api/coupon/exchange',
    method: 'post',
    data: params
  })
}
/**
 * 登录入口
 * @param {*} params 
 * @returns 
 */
export function api_authoirze (params) {
  return request({
    url: '/oauth/authoirze',
    method: 'get',
    params: params
  })
}
/**
 * 优惠券验证
 * @param {*} params 
 * @returns 
 */
export function api_couponVerfiy (params) {
  return request({
    url: '/api/coupon/verfiy',
    method: 'get',
    params: params
  })
}

/**
 * 获取商品信息
 * @param {*} params 
 * @returns 
 */
export function api_goods (params) {
  return request({
    url: '/api',
    method: 'get',
    params: params
  })
}


/**
 * 获取订单信息
 * @param {*} params 
 * @returns 
 */
export function api_order (params) {
  return request({
    url: '/api/order/' + params,
    method: 'get',
  })
}
